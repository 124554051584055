import { useQueryStates, type UseQueryStatesKeysMap, type Values } from "nuqs";
import type { ColumnFiltersState, Updater } from "@tanstack/react-table";
import { startTransition } from "react";

export function useFilterSearchParams<T extends UseQueryStatesKeysMap>(
  config: T
) {
  const [state, setState] = useQueryStates(config, {
    throttleMs: 600,
    startTransition: startTransition,
  });

  const toTableFilters = (filters: Values<T>) => {
    return Object.entries(filters).reduce((acc, [key, value]) => {
      acc.push({
        id: key,
        value: value,
      });
      return acc;
    }, [] as ColumnFiltersState);
  };

  const handleTableFilterChange = async (
    filterOrFn: Updater<ColumnFiltersState>
  ) => {
    const filters =
      typeof filterOrFn === "function"
        ? filterOrFn(toTableFilters(state))
        : filterOrFn;

    const allKeys = Object.keys(state);
    const filtersMap = Object.fromEntries(filters.map((f) => [f.id, f.value]));

    const newState = allKeys.reduce(
      (acc, key) => {
        acc[key] = filtersMap[key] !== undefined ? filtersMap[key] : null;
        return acc;
      },
      {} as Record<string, any>
    );

    await setState(newState as Values<T>);
  };

  const tableFilterState = toTableFilters(state);

  return {
    filters: state,
    setFilters: setState,
    tableFilterState,
    handleTableFilterChange,
  };
}
