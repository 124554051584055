import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const badgeVariants = cva(
  "inline-flex items-center rounded-sm border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      border: {
        dashed: "border-dashed",
        solid: "border-solid",
      },
      variant: {
        default:
          "border-transparent bg-primary text-primary-foreground hover:bg-primary/80",
        secondary:
          "bg-secondary/90 text-secondary-foreground hover:bg-secondary/80",
        destructive:
          "bg-red-200/90 text-red-800 border-red-300 hover:bg-red-200 text-red-800 hover:border-red-300",
        outline: "text-foreground",
        dashed: "border-dashed text-foreground",
        success:
          "bg-gossip-200/90 border border-gossip-500/60 text-gossip-800 hover:bg-gossip-200 hover:border-gossip-500/80",
        warn: "bg-amber-200/90 border border-amber-500/60 text-amber-800 hover:bg-amber-200 hover:border-amber-500/80",
        processing:
          "bg-purple-200/90 border border-purple-500/60 text-purple-800 hover:bg-purple-200 hover:border-purple-500/80",
        slate:
          "bg-slate-200/90 text-slate-800 border-slate-500/60 hover:bg-slate-200 hover:border-slate-500/80",
      },
    },
    defaultVariants: {
      variant: "default",
      border: "solid",
    },
  }
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, border, ...props }: BadgeProps) {
  return (
    <div
      className={cn(badgeVariants({ border, variant }), className)}
      {...props}
    />
  );
}

export { Badge };
