import type { ColumnDef } from "@tanstack/react-table";

import { DataTableColumnHeader } from "@/components/table/data-table-column-header.tsx";
import { DataTableRowActions } from "@/components/table/data-table-row-actions.tsx";

import { Checkbox } from "@/components/ui/checkbox.tsx";
import { CheckCheck, X } from "lucide-react";
import { Badge } from "@/components/ui/badge.tsx";
import { useSearchParams } from "react-router-dom";

const statuses = [
  {
    id: "active",
    label: "Ativo",
    icon: CheckCheck,
  },
  {
    id: "inactive",
    label: "Inativo",
    icon: X,
  },
];

export const columns: ColumnDef<{
  id?: string;
  name: string;
  email: string;
  role: "admin" | "operator" | "user";
  status: "active" | "inactive" | "deleted";
}>[] = [
  {
    id: "select",
    enableSorting: false,
    enableHiding: false,
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="translate-y-[2px]"
      />
    ),
  },
  {
    accessorKey: "name",
    enableSorting: false,
    header: ({ column }) => (
      <DataTableColumnHeader title="Nome" column={column} />
    ),
    cell: ({ row }) => <div>{row.getValue("name")}</div>,
  },
  {
    accessorKey: "email",
    enableHiding: false,
    header: ({ column }) => (
      <DataTableColumnHeader title="Email" column={column} />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2 min-w-[80px]">
          <span className="font-medium">{row.getValue("email")}</span>
        </div>
      );
    },
  },
  {
    accessorKey: "status",
    enableHiding: false,
    header: ({ column }) => (
      <DataTableColumnHeader title="Status" column={column} />
    ),
    cell: ({ row }) => {
      const status = statuses.find(
        (status) => status.id === row.getValue("status")
      );

      if (!status) {
        return null;
      }

      return (
        <Badge className="flex w-fit items-center">
          {status.icon && <status.icon className="mr-2 h-4 w-4 text-accent" />}
          <span>{status.label}</span>
        </Badge>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    id: "actions",
    cell: ({ row }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [, setSearchParams] = useSearchParams();

      const id = row.original.id;

      if (!id) {
        return null;
      }

      return (
        <DataTableRowActions
          row={row}
          options={[
            {
              label: "Editar",
              onClick: () => {
                setSearchParams({
                  id: id,
                });
              },
            },
            // {
            //   label: "Excluir",
            //   onClick: () =>
            //     setSearchParams({
            //       id: id,
            //     }),
            // },
          ]}
        />
      );
    },
  },
];
