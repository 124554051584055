import { DataTable } from "@/components/table/data-table.tsx";
import { productSentColumns } from "./product-sent-columns.tsx";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { DataTablePagination } from "@/components/table/data-table-pagination.tsx";
import { DataTableToolbar } from "@/components/table/data-table-toolbar.tsx";
import { DataTableSearch } from "@/components/table/data-table-search.tsx";
import {
  PageContainer,
  PageContent,
  PageDescription,
  PageHeader,
  PageTitle,
} from "@/components/layout/page.tsx";
import { type ApiResponse, fetchApi } from "@/lib/api.ts";
import { useFilterSearchParams } from "@/hooks/use-filter-search-params.ts";
import { parseAsArrayOf, parseAsString } from "nuqs";
import { useDebounce } from "@/hooks/use-debounce.tsx";
import { usePaginationSearchParams } from "@/hooks/use-pagination-search-params.ts";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useOrderBySearchParams } from "@/hooks/use-order-by-search-params.ts";
import {
  CardButton,
  CardButtonContent,
  CardButtonDescription,
  CardButtonIcon,
} from "@/components/card-button.tsx";
import { FilePlus } from "@phosphor-icons/react";
import { DataTableFacetedFilter } from "@/components/table/data-table-faceted-filter.tsx";

type SentProductInvoiceResponse = ApiResponse<{
  invoices: Array<{
    id: string;
    status: string;
    issuer: {
      document: string;
      name: string;
    };
    recipient: {
      document: string;
      name: string;
    };
    issuedAt: string;
  }>;
  pagination: {
    total: number;
    totalPages: number;
  };
}>;

export function SentProductInvoices() {
  const { filters, tableFilterState, handleTableFilterChange } =
    useFilterSearchParams({
      status: parseAsArrayOf(parseAsString).withDefault([]),
      approvalStatus: parseAsArrayOf(parseAsString).withDefault([]),
      issuer: parseAsString,
      recipient: parseAsString,
    });

  const { paginationState, handleTablePaginationChange } =
    usePaginationSearchParams();

  const { sortState, tableSortingState, handleTableSortingChange } =
    useOrderBySearchParams({
      orderBy: "issuedAt",
      orderDirection: "desc",
    });

  const debouncedFilters = useDebounce(filters, 500);
  const debouncedPaginationState = useDebounce(paginationState, 500);

  const { data: response } = useQuery({
    queryKey: [
      "/invoices/products/sent",
      debouncedPaginationState,
      debouncedFilters,
      sortState,
    ],
    queryFn: () =>
      fetchApi
        .get<SentProductInvoiceResponse>("/invoices/products/sent", {
          params: {
            limit: debouncedPaginationState.pageSize,
            page: debouncedPaginationState.pageIndex + 1,
            ...debouncedFilters,
            ...sortState,
          },
        })
        .then((res) => res.data.data),
    placeholderData: keepPreviousData,
  });

  const table = useReactTable({
    columns: productSentColumns,
    data: response?.invoices ?? [],
    state: {
      pagination: paginationState,
      columnFilters: tableFilterState,
      sorting: tableSortingState,
    },
    onPaginationChange: handleTablePaginationChange,
    onColumnFiltersChange: handleTableFilterChange,
    onSortingChange: handleTableSortingChange,
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    pageCount: response?.pagination?.totalPages ?? -1,
  });

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Notas de Produto Emitidas</PageTitle>
        <PageDescription>
          Gerencie todas as notas emitidas até o momento pelo nosso sistema.
        </PageDescription>
      </PageHeader>
      <PageContent className="flex flex-col gap-4">
        <CardButton className="w-fit" navigateTo="/invoices/product/new">
          <CardButtonContent>
            <CardButtonIcon>
              <FilePlus size={20} weight="fill" />
            </CardButtonIcon>
            <CardButtonDescription>Emitir nova NFe</CardButtonDescription>
          </CardButtonContent>
        </CardButton>
        <div className="space-y-4">
          <DataTableToolbar table={table}>
            <DataTableFacetedFilter
              title="Status Sefaz"
              column={table.getColumn("status")}
              options={[
                {
                  label: "Autorizada",
                  value: "authorized",
                },
                {
                  label: "Cancelada",
                  value: "cancelled",
                },
                {
                  label: "Denegada",
                  value: "denied",
                },
              ]}
            />
            <DataTableSearch
              placeholder="Emitente"
              column={table.getColumn("issuer")!}
            />
            <DataTableSearch
              placeholder="Tomador"
              column={table.getColumn("recipient")!}
            />
          </DataTableToolbar>
          <DataTable table={table} />
          <DataTablePagination table={table} />
        </div>
      </PageContent>
    </PageContainer>
  );
}
