import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog.tsx";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Form, FormField } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Label } from "@/components/ui/label.tsx";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchApi, isApiError } from "@/lib/api.ts";
import { PendingButton } from "@/components/pending-button.tsx";
import { toast } from "sonner";
import { popModal } from "@/modals";

const createUserSchema = z.object({
  name: z.string().min(1, "Use um nome válido"),
  email: z.string().email("Use um email válido"),
});

export function CreateUserModal() {
  const queryClient = useQueryClient();

  const { mutate: createUser, isPending } = useMutation({
    mutationKey: ["create-user"],
    mutationFn: async (data: { name: string; email: string }) =>
      fetchApi.post("/users", data),
    onError: (error) => {
      if (isApiError(error)) {
        toast.error(
          error.response?.data.error.message || "Ocorreu um erro desconhecido."
        );
      } else {
        toast.error("Ocorreu um erro desconhecido.");
      }
    },
    onSuccess: async (_, variables) => {
      toast.success(`Novo usuário ${variables.name} criado com sucesso.`);

      await queryClient.invalidateQueries({
        queryKey: ["users"],
      });

      popModal("CreateUserModal");
    },
  });

  const form = useForm({
    defaultValues: {
      name: "",
      email: "",
    },
    resolver: zodResolver(createUserSchema),
    mode: "onBlur",
  });

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Criando novo usuário</DialogTitle>
        <DialogDescription>
          Você está adicionando um novo usuário ao sistema.
        </DialogDescription>
      </DialogHeader>
      <Form {...form}>
        <form
          className="flex flex-col gap-4"
          onSubmit={form.handleSubmit(async (data) => createUser(data))}
        >
          <FormField
            name="name"
            render={({ field }) => (
              <div className="flex flex-col gap-2">
                <Label htmlFor="name">Nome pessoal</Label>
                <Input {...field} placeholder="John Doe" required={true} />
              </div>
            )}
          />
          <FormField
            name="email"
            render={({ field }) => (
              <div className="flex flex-col gap-2">
                <Label htmlFor="email">E-mail</Label>
                <Input
                  {...field}
                  placeholder="exemplo@exemplo.com"
                  required={true}
                />
              </div>
            )}
          />
          <PendingButton pending={isPending} type="submit">
            Criar usuário
          </PendingButton>
        </form>
      </Form>
    </DialogContent>
  );
}
