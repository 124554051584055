import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import { ChevronRight, Clock } from "lucide-react";
import { useQuery } from "@tanstack/react-query";
import { Badge } from "@/components/ui/badge.tsx";
import { cn } from "@/lib/utils.ts";
import { pushModal } from "@/modals";
import {
  PageContainer,
  PageContent,
  PageHeader,
  PageTitle,
} from "@/components/layout/page.tsx";
import { DashboardCharts } from "@/pages/overview/dashboard-charts.tsx";
import { DashboardStats } from "@/pages/overview/dashboard-stats.tsx";

type RecentInvoice = {
  id: string;
  invoiceType: string;
  status: string;
  type: string;
  createdAt: string;
  company: {
    id: string;
    name: string;
  };
  vendor: {
    id: string;
    name: string;
  };
};

const dateFormatter = new Intl.DateTimeFormat("pt-BR", {
  dateStyle: "medium",
  timeStyle: "short",
});

function Dashboard() {
  const { data: recentDocuments } = useQuery<{
    invoices: RecentInvoice[];
  }>({
    queryKey: ["invoices/recent"],
  });

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Dashboard</PageTitle>
      </PageHeader>
      <PageContent className="flex flex-1 gap-4 overflow-hidden p-4 sm:gap-6 sm:p-6">
        <div className="flex flex-1 flex-col gap-4 overflow-hidden sm:gap-6">
          <DashboardStats />
          <DashboardCharts />
        </div>
        <Card className="hidden lg:flex lg:flex-col">
          <CardHeader>
            <CardTitle className="inline-flex items-center gap-2">
              <Clock className="size-6 text-amber-500" />
              Documentos recentes
            </CardTitle>
            <CardDescription>
              Processados recentemente na plataforma
            </CardDescription>
          </CardHeader>
          <CardContent className="flex flex-col gap-2 flex-1 overflow-auto">
            {recentDocuments?.invoices.map((doc) => {
              const modalType = (() => {
                if (doc.type === "sent") {
                  if (doc.invoiceType === "service") {
                    return "ViewSentServiceInvoice";
                  } else if (doc.invoiceType === "product") {
                    return "ViewSentProductInvoice";
                  }
                }

                if (doc.type === "received") {
                  if (doc.invoiceType === "product") {
                    return "ViewReceivedProductInvoice";
                  }
                }
              })();

              return (
                <Card
                  key={doc.id}
                  className={cn(
                    "relative hover:shadow-md transition-all cursor-pointer animate-fade-in"
                  )}
                  onClick={() =>
                    modalType && pushModal(modalType, { id: doc.id })
                  }
                >
                  <CardContent className="p-4">
                    <div className="flex flex-col space-y-2">
                      <div className="flex items-center gap-2 flex-wrap">
                        <Badge
                          variant="slate"
                          border="dashed"
                          className="text-xs"
                        >
                          {doc.invoiceType === "service"
                            ? `NFSe ${doc.type === "sent" ? "Enviada" : "Recebida"}`
                            : `NFe ${doc.type === "sent" ? "Enviada" : "Recebida"}`}
                        </Badge>
                        <Badge
                          variant={
                            ["authorized", "success"].includes(doc.status)
                              ? "success"
                              : ["cancelled", "error"].includes(doc.status)
                                ? "destructive"
                                : "warn"
                          }
                        >
                          {
                            {
                              authorized: "Autorizada",
                              success: "Concluída",
                              cancelled: "Cancelada",
                              error: "Erro",
                              pending: "Pendente",
                              processing: "Processando",
                            }[doc.status]
                          }
                        </Badge>
                      </div>
                      <div className="flex flex-col justify-between text-xs">
                        <p className="text-slate-800 truncate w-72">
                          <span className="font-semibold mr-0.5">
                            {doc.type === "sent" ? "Emitente" : "Recebedor"}:
                          </span>
                          <span>{doc.company.name}</span>
                        </p>
                        <p className="text-slate-800 truncate w-72">
                          <span className="font-semibold mr-0.5">
                            {doc.type === "sent" ? "Destinatário" : "Remetente"}
                            :
                          </span>
                          <span>{doc.vendor.name}</span>
                        </p>
                        <ChevronRight className="absolute right-2 size-5 text-slate-400" />
                      </div>
                      <p className="text-xs text-slate-500">
                        {dateFormatter.format(new Date(doc.createdAt))}
                      </p>
                    </div>
                  </CardContent>
                </Card>
              );
            })}
          </CardContent>
          <CardFooter className="h-5"></CardFooter>
        </Card>
      </PageContent>
    </PageContainer>
  );
}

export { Dashboard };
