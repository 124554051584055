import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { AuthLayout } from "@/pages/auth/_layout.tsx";
import { Layout } from "@/pages/_layout.tsx";
import { UserAuthForm } from "@/pages/auth/login.tsx";
import { AuthCodeUserForm } from "@/pages/auth/code.tsx";
import { Dashboard } from "@/pages/dashboard.tsx";
import { CreateCompany } from "@/pages/companies/create";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { fetchApi } from "@/lib/api.ts";
import { CompaniesView } from "@/pages/companies";
import { CompanyEditLayout } from "@/pages/companies/[document]/_layout.tsx";
import { CompanyBasicInfoEdit } from "@/pages/companies/[document]/edit.tsx";
import { CompanyTaxesEdit } from "@/pages/companies/[document]/taxes.tsx";
import { CompanyServicesEdit } from "@/pages/companies/[document]/services.tsx";
import { CompanySettingsEdit } from "@/pages/companies/[document]/settings.tsx";
import { CreateProductInvoice } from "@/pages/invoices/product/create.tsx";
import { ImportNfe } from "@/pages/invoices/import/nfe.tsx";
import { ImportServiceDocument } from "@/pages/invoices/import/nfse.tsx";
import { CreateServiceInvoice } from "@/pages/invoices/service/create.tsx";
import { setDefaultOptions } from "date-fns";
import { ptBR } from "date-fns/locale";
import { toast } from "sonner";
import { Users } from "@/pages/users";
import { ViewVendors } from "@/pages/vendors";
import { CreateNewVendor } from "@/pages/vendors/new";
import { VendorEditLayout } from "@/pages/vendors/[document]/_layout.tsx";
import { VendorEdit } from "@/pages/vendors/[document]/edit.tsx";
import { VendorHistory } from "@/pages/vendors/[document]/history.tsx";
import { ModalProvider } from "@/modals";
import { isAxiosError } from "axios";
import { BaseLayout } from "@/components/layout/base-layout.tsx";
import { InvoicesBatchesLayout } from "@/pages/invoices/batches/_layout.tsx";
import { Batches } from "@/pages/invoices/batches";
import { BatchView } from "@/pages/invoices/batches/[batchId]";
import { InvalidRoute } from "@/components/error-boundary/invalid-route.tsx";
import { Blog } from "@/pages/blog";
import { BlogPost } from "@/pages/blog/posts";
import { ReceivedProductInvoices } from "@/pages/invoices/product-received/product-received.tsx";
import { SentProductInvoices } from "@/pages/invoices/product-sent/product-sent.tsx";
import { SentServiceInvoices } from "@/pages/invoices/service-sent/service-sent.tsx";

setDefaultOptions({
  locale: ptBR,
});

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<BaseLayout />}>
      <Route path="*" element={<InvalidRoute />} />
      <Route path="/auth">
        <Route element={<AuthLayout />}>
          <Route path="login" element={<UserAuthForm />} />
          <Route path="code" element={<AuthCodeUserForm />} />
        </Route>
      </Route>
      <Route element={<Layout />}>
        <Route path="/" index={true} element={<Dashboard />} />
        <Route path="/invoices">
          <Route path="service" handle={{ crumb: "Serviços" }}>
            <Route
              path="new"
              element={<CreateServiceInvoice />}
              handle={{
                crumb: "Emitir NFS-e",
              }}
            />
            <Route path="sent" handle={{ crumb: "Notas emitidas" }}>
              <Route index={true} element={<SentServiceInvoices />} />
            </Route>
          </Route>

          <Route path="product" handle={{ crumb: "Produtos" }}>
            <Route
              path="new"
              element={<CreateProductInvoice />}
              handle={{
                crumb: "Emitir NFE-e",
              }}
            />
            <Route path="sent" handle={{ crumb: "Notas emitidas" }}>
              <Route index={true} element={<SentProductInvoices />} />
            </Route>
            <Route path="received" handle={{ crumb: "Notas recebidas" }}>
              <Route index={true} element={<ReceivedProductInvoices />} />
            </Route>
          </Route>

          <Route
            path="batches"
            element={<InvoicesBatchesLayout />}
            handle={{
              crumb: "Lotes",
            }}
          >
            <Route index={true} element={<Batches />} />
            <Route
              path=":batchId"
              element={<BatchView />}
              handle={{
                crumb: "Informações do lote",
              }}
            />
            <Route path="import">
              <Route
                path="product"
                element={<ImportNfe />}
                handle={{
                  crumb: "Importar NFe",
                }}
              />
              <Route
                path="service"
                element={<ImportServiceDocument />}
                handle={{
                  crumb: "Importar NFS-e",
                }}
              />
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          </Route>
        </Route>
        <Route path="/companies" handle={{ crumb: "Empresas" }}>
          <Route index={true} element={<CompaniesView />} />
          <Route
            path="create"
            element={<CreateCompany />}
            handle={{ crumb: "Criar nova empresa" }}
          />
          <Route path=":document" element={<CompanyEditLayout />}>
            <Route
              path="edit"
              element={<CompanyBasicInfoEdit />}
              handle={{
                crumb: "Informações básicas",
              }}
            />
            <Route
              path="taxes"
              element={<CompanyTaxesEdit />}
              handle={{
                crumb: "Tributos",
              }}
            />
            <Route
              path="services"
              element={<CompanyServicesEdit />}
              handle={{
                crumb: "Serviços",
              }}
            />
            <Route
              path="settings"
              element={<CompanySettingsEdit />}
              handle={{
                crumb: "Configurações",
              }}
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Route>
        <Route path="/vendors" handle={{ crumb: "Fornecedores" }}>
          <Route index={true} element={<ViewVendors />} />
          <Route
            path="new"
            element={<CreateNewVendor />}
            handle={{
              crumb: "Novo fornecedor",
            }}
          />
          <Route path=":document" element={<VendorEditLayout />}>
            <Route
              path="edit"
              element={<VendorEdit />}
              handle={{
                crumb: "Informações básicas",
              }}
            />
            <Route
              path="history"
              element={<VendorHistory />}
              handle={{
                crumb: "Histórico",
              }}
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Route>
        <Route
          path="/users"
          element={<Users />}
          handle={{ crumb: "Usuários" }}
        />
        <Route path="/blog" handle={{ crumb: "Base de conhecimento" }}>
          <Route index={true} element={<Blog />} />
          <Route path=":category">
            <Route
              path=":post"
              element={<BlogPost />}
              handle={{ crumb: "Postagem" }}
            />
          </Route>
        </Route>
      </Route>
    </Route>
  )
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: ({ queryKey, signal }) =>
        fetchApi(`${queryKey[0]}`, {
          signal: signal,
        }).then((res) => res.data.data),
      retry: (failureCount, error) => {
        if (isAxiosError(error) && error.response) {
          if ([404, 401].includes(error.response.status)) {
            return false;
          }
        }

        return failureCount < 3;
      },
    },
    mutations: {
      onSuccess: () => {
        toast.success("Operação realizada com sucesso.", {
          position: "bottom-right",
        });
      },
      onError: () => {
        toast.error("Erro ao realizar operação.", {
          position: "bottom-right",
          className: "bg-red-500",
        });
      },
    },
  },
});

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ModalProvider />
      <ReactQueryDevtools
        initialIsOpen={true}
        position="bottom"
        buttonPosition="bottom-right"
      />
    </QueryClientProvider>
  );
}
