import { DataTable } from "@/components/data-table/data-table.tsx";
import { columns } from "@/pages/users/columns.tsx";
import { ViewUserDetails } from "@/pages/users/components/view-user-details.tsx";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DataTablePagination } from "@/components/data-table/data-table-pagination.tsx";
import { useQuery } from "@tanstack/react-query";
import {
  PageContainer,
  PageContent,
  PageDescription,
  PageHeader,
  PageTitle,
} from "@/components/layout/page.tsx";
import {
  CardButton,
  CardButtonContent,
  CardButtonDescription,
  CardButtonIcon,
} from "@/components/card-button.tsx";
import { Building } from "@phosphor-icons/react";
import { pushModal } from "@/modals";
import { useGenericTable } from "@/components/table/data-table.tsx";
import { DataTableViewOptions } from "@/components/data-table/data-table-view-options.tsx";
import { DataTableFacetedFilter } from "@/components/data-table/data-table-faceted-filter.tsx";

export function Users() {
  const { data } = useQuery<{
    users: {
      id?: string;
      name: string;
      email: string;
      role: "admin" | "operator" | "user";
      status: "active" | "inactive" | "deleted";
    }[];
  }>({
    queryKey: ["users"],
  });

  const navigate = useNavigate();

  const table = useGenericTable({
    columns,
    data: data?.users ?? [],
  });

  const [search] = useSearchParams();
  const userId = search.get("id");

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Usuários</PageTitle>
        <PageDescription>
          Gerencie todos os usuários que estão cadastrados no sistema.
        </PageDescription>
      </PageHeader>
      <PageContent className="flex flex-col gap-4">
        <CardButton
          className="w-fit"
          onClick={() => pushModal("CreateUserModal")}
        >
          <CardButtonContent>
            <CardButtonIcon>
              <Building size={20} weight="fill" />
            </CardButtonIcon>
            <CardButtonDescription>Novo usuário</CardButtonDescription>
          </CardButtonContent>
        </CardButton>
        <div className="space-y-4">
          <DataTableFacetedFilter
            label="Status do usuário"
            column={table.getColumn("status")!}
            options={[
              {
                label: "Ativo",
                value: "active",
              },
              {
                label: "Inativo",
                value: "inactive",
              },
            ]}
          />
          <DataTableViewOptions table={table}></DataTableViewOptions>
          <DataTable table={table} />
          <DataTablePagination table={table} />
        </div>
        <ViewUserDetails
          key={userId}
          userId={userId}
          onOpenChange={(open) => {
            if (!open) {
              navigate(-1);
            }
          }}
        />
      </PageContent>
    </PageContainer>
  );
}
