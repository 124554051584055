import { createParser, parseAsInteger, useQueryStates } from "nuqs";
import { useCallback } from "react";
import type { PaginationState, Updater } from "@tanstack/react-table";

const pageIndexParser = createParser({
  parse: (query) => {
    const page = parseAsInteger.parse(query);
    return page === null ? null : page - 1;
  },
  serialize: (value) => {
    return parseAsInteger.serialize(value + 1);
  },
});

const paginationParsers = {
  pageIndex: pageIndexParser.withDefault(0),
  pageSize: parseAsInteger.withDefault(10),
};
const paginationUrlKeys = {
  pageIndex: "page",
  pageSize: "perPage",
};

export function usePaginationSearchParams() {
  const [paginationState, setPaginationState] = useQueryStates(
    paginationParsers,
    {
      urlKeys: paginationUrlKeys,
    }
  );

  const handlePaginationChange = useCallback(
    async (updater: Updater<PaginationState>) => {
      const newPaginationValue =
        updater instanceof Function
          ? updater({
              pageIndex: paginationState.pageIndex,
              pageSize: paginationState.pageSize,
            })
          : updater;

      await setPaginationState({
        pageIndex: newPaginationValue.pageIndex,
        pageSize: newPaginationValue.pageSize,
      });
    },
    [paginationState, setPaginationState]
  );

  return {
    paginationState,
    setPaginationState,
    handleTablePaginationChange: handlePaginationChange,
  };
}
