import { parseAsString, useQueryStates } from "nuqs";
import type { SortingState, Updater } from "@tanstack/react-table";
import { useCallback } from "react";

export function useOrderBySearchParams(initial: {
  orderBy: string;
  orderDirection: string;
}) {
  const [sortState, setSortState] = useQueryStates(
    {
      orderBy: parseAsString.withDefault(initial.orderBy),
      orderDirection: parseAsString.withDefault(initial.orderDirection),
    },
    {
      urlKeys: {
        orderBy: "sortBy",
        orderDirection: "sortDir",
      },
    }
  );

  const tableSortingState = sortState.orderBy
    ? [
        {
          id: sortState.orderBy,
          desc: sortState.orderDirection === "desc",
        },
      ]
    : [];

  const handleTableSortingChange = useCallback(
    async (updater: Updater<SortingState>) => {
      const newSortingValue =
        updater instanceof Function
          ? updater(
              sortState.orderBy
                ? [
                    {
                      id: sortState.orderBy,
                      desc: sortState.orderDirection === "desc",
                    },
                  ]
                : []
            )
          : updater;

      await setSortState({
        orderBy: newSortingValue[0].id,
        orderDirection: newSortingValue[0].desc ? "desc" : "asc",
      });
    },
    [sortState, setSortState]
  );

  return {
    sortState,
    setSortState,
    tableSortingState,
    handleTableSortingChange,
  };
}
