import axios, { type AxiosError, isAxiosError } from "axios";

export const fetchApi = axios.create({
  baseURL: import.meta.env.VITE_API_ENDPOINT,
  withCredentials: true,
});

export interface ApiResponse<T> {
  data: T;
  status: "success";
}

export function isApiError(
  error: unknown
): error is AxiosError<{ status: "error"; error: { message: string } }> {
  if (isAxiosError(error)) {
    if (!error.response?.data) {
      return false;
    }

    if (error.response.data?.status === "error") {
      return true;
    }
  }

  return false;
}
